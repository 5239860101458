import { IconDefinition } from '@fortawesome/free-solid-svg-icons'

import { ICON_LIST } from 'src/common/constants/icon/iconList'
import { all_users } from 'src/common/constants/roles-rights/roles'

export interface TMenuItem {
  key: string
  title: string

  url?: string
  icon?: IconDefinition
  hasProfile: string[]
  hasRight?: string[]
  children?: TMenuItem[]
}

// SETTINGS MENU
const SETTINGS: TMenuItem[] = [
  {
    key: 'settings',
    title: 'NAVIGATION_MENU.SETTINGS',
    url: '/',
    icon: ICON_LIST.COG,
    hasProfile: all_users,
    children: [
      {
        key: 'role-right-list',
        title: 'NAVIGATION_MENU.ROLE_RIGHT',
        url: '/settings/role-right',
        icon: ICON_LIST.ANGLE_RIGHT,
        hasProfile: all_users
      },
      {
        key: 'users',
        title: 'NAVIGATION_MENU.USERS',
        url: '/settings/users',
        icon: ICON_LIST.ANGLE_RIGHT,
        hasProfile: all_users
      },
      {
        key: 'blocked-users',
        title: 'NAVIGATION_MENU.BLOCKED_USERS',
        url: '/settings/blocked-users',
        icon: ICON_LIST.ANGLE_RIGHT,
        hasProfile: all_users
      }
    ]
  }
]

// COMPLAINT_MANAGEMENT MENU
const COMPLAINT_MANAGEMENT: TMenuItem[] = [
  {
    key: 'complaint-management',
    title: 'NAVIGATION_MENU.COMPLAINT_MANAGEMENT',
    url: '/complaint-management',
    icon: ICON_LIST.SCROLL,
    hasProfile: all_users
  }
]
// ANNOUNCEMENT_MENU MENU
const ANNOUNCEMENT_MENU: TMenuItem[] = [
  {
    key: 'announcement-management',
    title: 'NAVIGATION_MENU.ANNOUNCEMENT_MANAGEMENT',
    url: '/announcement-management',
    icon: ICON_LIST.BULLHORN,
    hasProfile: all_users
  }
]

// CONTACT_US MENU
const CONTACT_US_MENU: TMenuItem[] = [
  {
    key: 'contact-us',
    title: 'NAVIGATION_MENU.CONTACT_US',
    url: '/contact-us',
    icon: ICON_LIST.ADDRESS_BOOK,
    hasProfile: all_users
  }
]

// APP_MANAGEMENT_MENU
const APP_MANAGEMENT_MENU: TMenuItem[] = [
  {
    key: 'app-management',
    title: 'NAVIGATION_MENU.APP_MANAGEMENT',
    url: '/',
    icon: ICON_LIST.MOBILE_PHONE,
    hasProfile: all_users,
    children: [
      {
        key: 'carousel-management',
        title: 'NAVIGATION_MENU.CAROUSEL_MANAGEMENT',
        url: 'carousel-management',
        icon: ICON_LIST.ANGLE_RIGHT,
        hasProfile: all_users
      }
    ]
  }
]

// USERS MANAGEMENT MENU
const USERS_MANAGEMENT_MENU: TMenuItem[] = [
  {
    key: 'users-management',
    title: 'NAVIGATION_MENU.USERS_MANAGEMENT',
    url: '/users-management',
    icon: ICON_LIST.USER,
    hasProfile: all_users
  }
]

// MESSAGING_MANAGEMENT MENU
const MESSAGING_MANAGEMENT_MENU: TMenuItem[] = [
  {
    key: 'messaging-management',
    title: 'NAVIGATION_MENU.MESSAGING_MANAGEMENT',
    url: '/messaging-management',
    icon: ICON_LIST.ENVELOPE,
    hasProfile: all_users
  }
]

// SOCIAL_FEED_MANAGEMENT MENU
const SOCIAL_FEED_MANAGEMENT: TMenuItem[] = [
  {
    key: 'social-feed-management',
    title: 'NAVIGATION_MENU.SOCIAL_FEED_MANAGEMENT',
    url: '/social-feed-management',
    icon: ICON_LIST.SHARE_NODES,
    hasProfile: all_users,
    children: [
      {
        key: 'social-feed-management-1',
        title: 'NAVIGATION_MENU.SOCIAL_FEED',
        url: '/social-feed',
        icon: ICON_LIST.ANGLE_RIGHT,
        hasProfile: all_users
      },
      {
        key: 'social-feed-management-2',
        title: 'NAVIGATION_MENU.PAWDER_OFFICIAL',
        url: '/pawder-official',
        icon: ICON_LIST.ANGLE_RIGHT,
        hasProfile: all_users
      },
      {
        key: 'social-feed-management-3',
        title: 'NAVIGATION_MENU.CATEGORY_MANAGEMENT',
        url: '/category-management',
        icon: ICON_LIST.ANGLE_RIGHT,
        hasProfile: all_users
      }
    ]
  }
]

// EDUCATION_FEED_MANAGEMENT MENU
const EDUCATION_FEED_MANAGEMENT: TMenuItem[] = [
  {
    key: 'education-feed-management',
    title: 'NAVIGATION_MENU.EDUCATION_FEED_MANAGEMENT',
    url: '/education-feed-management',
    icon: ICON_LIST.SCHOOL,
    hasProfile: all_users
  }
]

// MENU
export const MENU: TMenuItem[] = [
  {
    key: 'home',
    title: 'NAVIGATION_MENU.DASHBOARD',
    url: '/',
    icon: ICON_LIST.HOME,
    hasProfile: all_users
  },
  ...USERS_MANAGEMENT_MENU,
  ...COMPLAINT_MANAGEMENT,
  ...APP_MANAGEMENT_MENU,
  ...ANNOUNCEMENT_MENU,
  ...CONTACT_US_MENU,
  ...MESSAGING_MANAGEMENT_MENU,
  ...SOCIAL_FEED_MANAGEMENT,
  ...EDUCATION_FEED_MANAGEMENT,
  ...SETTINGS
]
