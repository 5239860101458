export enum TYPES {
  // Auth
  AUTH_LOGIN = 'AUTH_LOGIN',
  AUTH_REGISTER = 'AUTH_REGISTER',
  AUTH_LOGOUT = 'AUTH_LOGOUT',
  AUTH_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN',
  AUTH_SET_USER_TOKEN = 'AUTH_SET_USER_TOKEN',

  // Chat
  CHAT_LIST = 'CHAT_LIST',
  CHAT_DETAILS = 'CHAT_DETAILS',

  // FILE
  CRM_FILE_UPLOAD = 'CRM_FILE_UPLOAD',

  // Crm User
  CRM_USER_LIST = 'CRM_USER_LIST',
  CRM_USER_DETAILS = 'CRM_USER_DETAILS',
  CRM_USER_DELETE = 'CRM_USER_DELETE',
  CRM_USER_UPDATE = 'CRM_USER_UPDATE',

  // History
  HISTORY_LIST = 'HISTORY_LIST',
  HISTORY_DETAIL = 'HISTORY_DETAIL',
  HISTORY_PET_HISTORIES = 'HISTORY_PET_HISTORIES',
  HISTORY_USER_HISTORIES = 'HISTORY_USER_HISTORIES',
  HISTORY_MESSAGE_HISTORIES = 'HISTORY_MESSAGE_HISTORIES',
  HISTORY_FOLLOWER_HISTORIES = 'HISTORY_FOLLOWER_HISTORIES',
  HISTORY_FOLLOWING_HISTORIES = 'HISTORY_FOLLOWING_HISTORIES',
  HISTORY_PURCHASE_HISTORIES = 'HISTORY_PURCHASE_HISTORIES',
  HISTORY_BLOCKED_HISTORIES = 'HISTORY_BLOCKED_HISTORIES',

  // Report
  REPORT_LIST = 'REPORT_LIST',
  REPORT_DETAILS = 'REPORT_DETAILS',
  REPORT_STATUS_UPDATE = 'REPORT_STATUS_UPDATE',

  // User
  USER_LIST = 'USER_LIST',
  USER_DETAILS = 'USER_DETAILS',
  USER_UPDATE_ROLE = 'USER_UPDATE_ROLE',
  USER_FOLLOWERS = 'USER_FOLLOWERS',
  USER_FOLLOWINGS = 'USER_FOLLOWINGS',
  CHANGE_USER_STATUS = 'CHANGE_USER_STATUS',
  USER_BLOCKED_LIST = 'USER_BLOCKED_LIST',
  BLOCKED_USER_LIST = 'BLOCKED_USER_LIST',
  POST_BLOCK_USER = 'POST_BLOCK_USER',
  POST_UNBLOCK_USER = 'POST_UNBLOCK_USER',

  // App
  CAROUSEL_LIST = 'CAROUSEL_LIST',
  CREATE_CAROUSEL = 'CREATE_CAROUSEL',
  DELETE_CAROUSEL = 'DELETE_CAROUSEL',
  UPDATE_CAROUSEL = 'UPDATE_CAROUSEL_IMAGE',

  // Photo
  DELETE_PHOTO = 'DELETE_PHOTO',

  // Announcements
  ANNOUNCEMENT_LIST = 'ANNOUNCEMENT_LIST',
  DELETE_ANNOUNCEMENT_DRAFT = 'DELETE_ANNOUNCEMENT_DRAFT',
  EDIT_ANNOUNCEMENT_DRAFT = 'EDIT_ANNOUNCEMENT_DRAFT',
  CREATE_ANNOUNCEMENT_DRAFT = 'CREATE_ANNOUNCEMENT_DRAFT',
  PUBLISH_ANNOUNCEMENT_DRAFT = 'PUBLISH_ANNOUNCEMENT_DRAFT',
  CREATE_PRIVATE_ANNOUNCEMENT_DRAFT = 'CREATE_PRIVATE_ANNOUNCEMENT_DRAFT',
  EDIT_PRIVATE_ANNOUNCEMENT_DRAFT = 'EDIT_PRIVATE_ANNOUNCEMENT_DRAFT',
  DELETE_PRIVATE_ANNOUNCEMENT_DRAFT = 'DELETE_PRIVATE_ANNOUNCEMENT_DRAFT',
  PRIVATE_ANNOUNCEMENT_LIST = 'PRIVATE_ANNOUNCEMENT_LIST',
  PUBLISH_PRIVATE_ANNOUNCEMENT_DRAFT = 'PUBLISH_PRIVATE_ANNOUNCEMENT_DRAFT',

  // Contact Us
  CONTACT_US_LIST = 'CONTACT_US_LIST',

  // Set Modal Visible
  SET_MODAL_VISIBLE = 'SET_MODAL_VISIBLE',

  // Social Feed
  SOCIAL_POST_LIST = 'SOCIAL_POST_LIST',
  SOCIAL_OFFICIAL_POSTS = 'SOCIAL_OFFICIAL_POSTS',
  SOCIAL_OFFICIAL_POST_DELETE = 'SOCIAL_OFFICIAL_POST_DELETE',
  SOCIAL_OFFICIAL_POST_CREATE = 'SOCIAL_OFFICIAL_POST_CREATE',
  SOCIAL_OFFICIAL_POST_UPDATE = 'SOCIAL_OFFICIAL_POST_UPDATE',
  SOCIAL_USER_POSTS = 'SOCIAL_USER_POSTS',
  SOCIAL_OFFICIAL_STORIES = 'SOCIAL_OFFICIAL_STORIES',
  SOCIAL_USER_STORIES = 'SOCIAL_USER_STORIES',
  SOCIAL_POSTS_BY_TAG = 'SOCIAL_POSTS_BY_TAG',
  SOCIAL_POST_BY_ID = 'SOCIAL_POST_BY_ID',
  SOCIAL_POST_DELETE = 'SOCIAL_POST_DELETE',
  SOCIAL_GET_HASHTAGS = 'SOCIAL_GET_HASHTAGS',
  SOCIAL_DELETE_HASHTAG = 'SOCIAL_DELETE_HASHTAG',
  SOCIAL_UPDATE_HASHTAG = 'SOCIAL_UPDATE_HASHTAG',
  SOCIAL_ADD_HASHTAG = 'SOCIAL_ADD_HASHTAG',
  SOCIAL_POST_COMMENTS = 'SOCIAL_POST_COMMENTS',
  SOCIAL_POST_UPDATE = 'SOCIAL_POST_UPDATE',
  SOCIAL_POST_RESTRICT_COMMENT = 'SOCIAL_POST_RESTRICT_COMMENT',

  // CRM COMMENT
  SOCIAL_DELETE_RELATED_COMMENTS = 'SOCIAL_DELETE_RELATED_COMMENTS',
  SOCIAL_COMMENTS_BY_POST_ID = 'SOCIAL_COMMENTS_BY_POST_ID',
  SOCIAL_DELETE_COMMENT_BY_ID = 'SOCIAL_DELETE_COMMENT_BY_ID',
  SOCIAL_GET_COMMENT_BY_ID = 'SOCIAL_GET_COMMENT_BY_ID',

  // Education Feed
  EDUCATION_LIST = 'EDUCATION_LIST',
  EDUCATION_DETAILS = 'EDUCATION_DETAILS',
  EDUCATION_CREATE = 'EDUCATION_CREATE',
  EDUCATION_UPDATE = 'EDUCATION_UPDATE',
  EDUCATION_DELETE = 'EDUCATION_DELETE',
  EDUCATION_CATEGORIES = 'EDUCATION_LIST_CATEGORIES',
  EDUCATION_CREATE_CATEGORY = 'EDUCATION_CREATE_CATEGORY',
  EDUCATION_UPDATE_CATEGORY = 'EDUCATION_UPDATE_CATEGORY',
  EDUCATION_DELETE_CATEGORY = 'EDUCATION_DELETE_CATEGORY',
  INSTRUCTOR_LIST = 'INSTRUCTOR_LIST',
  INSTRUCTOR_DETAILS = 'INSTRUCTOR_DETAILS',
  INSTRUCTOR_CREATE = 'INSTRUCTOR_CREATE',
  INSTRUCTOR_UPDATE = 'INSTRUCTOR_UPDATE',
  INSTRUCTOR_DELETE = 'INSTRUCTOR_DELETE'
}
