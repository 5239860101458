/* BILGI */
// Bu dosyada, fetcher için gerekli olan konfigürasyonlar bulunur.
// API ile çalışılırken kullanılan servisler, tipler, methodlar, başarılı ve başarısız durumlar gibi bilgiler burada bulunur.
import { USER_AUTH_REDUCER } from 'src/store/reducers/userAuth'

import { SERVICES } from './services'
import { TYPES } from './types'

// Minute
// const MINUTE = 1000 * 60;

// Client Config Types
export enum METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE'
}

enum CONTENT_TYPE {
  JSON = 'application/json', // Default
  FORM_DATA = 'multipart/form-data'
}

export interface TconfigObj {
  URL: string
  SERVICE: SERVICES
  TYPE: TYPES
  METHOD: METHOD
  SUCCESS: string | boolean
  ERROR: string | boolean
  RE_FETCH?: TYPES[]
  REDUCER_TYPE?: string
  CONTENT_TYPE?: CONTENT_TYPE | string
  CUSTOM_REQUEST_CONFIG?: {
    cacheTime?: number
    staleTime?: number
    gcTime?: number
  } // StaleTime = Ne kadar sürede cache'in geçersiz olacağıdır.
}

const AUTH_SERVICES = [
  {
    URL: '/login',
    SERVICE: SERVICES.AUTH,
    TYPE: TYPES.AUTH_LOGIN,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true,
    REDUCER_TYPE: USER_AUTH_REDUCER
  },
  {
    URL: '/register',
    SERVICE: SERVICES.AUTH,
    TYPE: TYPES.AUTH_REGISTER,
    METHOD: METHOD.POST,
    SUCCESS: true,
    ERROR: true,
    RE_FETCH: [TYPES.CRM_USER_LIST]
  },
  {
    URL: '/logout',
    SERVICE: SERVICES.AUTH,
    TYPE: TYPES.AUTH_LOGOUT,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/refreshToken',
    SERVICE: SERVICES.AUTH,
    TYPE: TYPES.AUTH_REFRESH_TOKEN,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true
  }
]

const CHAT_SERVICES = [
  {
    URL: '/list',
    SERVICE: SERVICES.CHAT,
    TYPE: TYPES.CHAT_LIST,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/chat/{1}',
    SERVICE: SERVICES.HISTORY,
    TYPE: TYPES.CHAT_DETAILS,
    METHOD: METHOD.GET,
    SUCCESS: false,
    ERROR: true,
    CUSTOM_REQUEST_CONFIG: {
      cacheTime: 0,
      staleTime: 0,
      gcTime: 0
    }
  }
]

const CRM_USER_SERVICES = [
  {
    URL: '/list',
    SERVICE: SERVICES.CRM,
    TYPE: TYPES.CRM_USER_LIST,
    METHOD: METHOD.GET,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/details/{1}',
    SERVICE: SERVICES.CRM,
    TYPE: TYPES.CRM_USER_DETAILS,
    METHOD: METHOD.GET,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/delete/{1}',
    SERVICE: SERVICES.CRM,
    TYPE: TYPES.CRM_USER_DELETE,
    METHOD: METHOD.DELETE,
    SUCCESS: true,
    ERROR: true,
    RE_FETCH: [TYPES.CRM_USER_LIST]
  },
  {
    URL: '/update',
    SERVICE: SERVICES.CRM,
    TYPE: TYPES.CRM_USER_UPDATE,
    METHOD: METHOD.PUT,
    SUCCESS: true,
    ERROR: true,
    RE_FETCH: [TYPES.CRM_USER_LIST]
  },
  {
    URL: '/carousel-list',
    SERVICE: SERVICES.CRM_FILE,
    TYPE: TYPES.CAROUSEL_LIST,
    METHOD: METHOD.GET,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/create-carousel',
    SERVICE: SERVICES.CRM_FILE,
    TYPE: TYPES.CREATE_CAROUSEL,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true,
    RE_FETCH: [TYPES.CAROUSEL_LIST]
  },
  {
    URL: '/delete-carousel',
    SERVICE: SERVICES.CRM_FILE,
    TYPE: TYPES.DELETE_CAROUSEL,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true,
    RE_FETCH: [TYPES.CAROUSEL_LIST]
  },
  {
    URL: '/update-carousel',
    SERVICE: SERVICES.CRM_FILE,
    TYPE: TYPES.UPDATE_CAROUSEL,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true,
    RE_FETCH: [TYPES.CAROUSEL_LIST]
  },
  {
    URL: '/reach-us/list',
    SERVICE: SERVICES.CRM,
    TYPE: TYPES.CONTACT_US_LIST,
    METHOD: METHOD.GET,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/topics',
    SERVICE: SERVICES.NOTIFICATION,
    TYPE: TYPES.ANNOUNCEMENT_LIST,
    METHOD: METHOD.GET,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/private-topics',
    SERVICE: SERVICES.NOTIFICATION,
    TYPE: TYPES.PRIVATE_ANNOUNCEMENT_LIST,
    METHOD: METHOD.GET,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/topic',
    SERVICE: SERVICES.NOTIFICATION,
    TYPE: TYPES.CREATE_ANNOUNCEMENT_DRAFT,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true,
    RE_FETCH: [TYPES.ANNOUNCEMENT_LIST]
  },
  {
    URL: '/private-topic',
    SERVICE: SERVICES.NOTIFICATION,
    TYPE: TYPES.CREATE_PRIVATE_ANNOUNCEMENT_DRAFT,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true,
    RE_FETCH: [TYPES.PRIVATE_ANNOUNCEMENT_LIST]
  },
  {
    URL: '/topic/{1}',
    SERVICE: SERVICES.NOTIFICATION,
    TYPE: TYPES.DELETE_ANNOUNCEMENT_DRAFT,
    METHOD: METHOD.DELETE,
    SUCCESS: false,
    ERROR: true,
    RE_FETCH: [TYPES.ANNOUNCEMENT_LIST]
  },
  {
    URL: '/private-topic/{1}',
    SERVICE: SERVICES.NOTIFICATION,
    TYPE: TYPES.DELETE_PRIVATE_ANNOUNCEMENT_DRAFT,
    METHOD: METHOD.DELETE,
    SUCCESS: false,
    ERROR: true,
    RE_FETCH: [TYPES.PRIVATE_ANNOUNCEMENT_LIST]
  },
  {
    URL: '/topic/{1}',
    SERVICE: SERVICES.NOTIFICATION,
    TYPE: TYPES.EDIT_ANNOUNCEMENT_DRAFT,
    METHOD: METHOD.PUT,
    SUCCESS: false,
    ERROR: true,
    RE_FETCH: [TYPES.ANNOUNCEMENT_LIST]
  },
  {
    URL: '/private-topic/{1}',
    SERVICE: SERVICES.NOTIFICATION,
    TYPE: TYPES.EDIT_PRIVATE_ANNOUNCEMENT_DRAFT,
    METHOD: METHOD.PUT,
    SUCCESS: false,
    ERROR: true,
    RE_FETCH: [TYPES.PRIVATE_ANNOUNCEMENT_LIST]
  },
  {
    URL: '/send',
    SERVICE: SERVICES.NOTIFICATION,
    TYPE: TYPES.PUBLISH_ANNOUNCEMENT_DRAFT,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true,
    RE_FETCH: [TYPES.ANNOUNCEMENT_LIST]
  },
  {
    URL: '/private-send',
    SERVICE: SERVICES.NOTIFICATION,
    TYPE: TYPES.PUBLISH_PRIVATE_ANNOUNCEMENT_DRAFT,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true,
    RE_FETCH: [TYPES.PRIVATE_ANNOUNCEMENT_LIST]
  },
  {
    URL: '/photo/delete',
    SERVICE: SERVICES.CRM,
    TYPE: TYPES.DELETE_PHOTO,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true
  }
]

const HISTORY_SERVICES = [
  {
    URL: '/list',
    SERVICE: SERVICES.HISTORY,
    TYPE: TYPES.HISTORY_LIST,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/detail',
    SERVICE: SERVICES.HISTORY,
    TYPE: TYPES.HISTORY_DETAIL,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/pet-histories',
    SERVICE: SERVICES.HISTORY,
    TYPE: TYPES.HISTORY_PET_HISTORIES,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/user-histories',
    SERVICE: SERVICES.HISTORY,
    TYPE: TYPES.HISTORY_USER_HISTORIES,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/follower-histories',
    SERVICE: SERVICES.HISTORY,
    TYPE: TYPES.HISTORY_FOLLOWER_HISTORIES,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/following-histories',
    SERVICE: SERVICES.HISTORY,
    TYPE: TYPES.HISTORY_FOLLOWING_HISTORIES,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/purchase-histories',
    SERVICE: SERVICES.HISTORY,
    TYPE: TYPES.HISTORY_PURCHASE_HISTORIES,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/blocked-histories',
    SERVICE: SERVICES.HISTORY,
    TYPE: TYPES.HISTORY_BLOCKED_HISTORIES,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true
  },

  {
    URL: '/message-histories',
    SERVICE: SERVICES.HISTORY,
    TYPE: TYPES.HISTORY_MESSAGE_HISTORIES,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true
  }
]

const REPORT_SERVICES = [
  {
    URL: '/get-reports',
    SERVICE: SERVICES.CRM_REPORT,
    TYPE: TYPES.REPORT_LIST,
    METHOD: METHOD.GET,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/get-report-by-id',
    SERVICE: SERVICES.CRM_REPORT,
    TYPE: TYPES.REPORT_DETAILS,
    METHOD: METHOD.GET,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/update-report-status',
    SERVICE: SERVICES.CRM_REPORT,
    TYPE: TYPES.REPORT_STATUS_UPDATE,
    METHOD: METHOD.POST,
    SUCCESS: true,
    ERROR: true,
    RE_FETCH: [TYPES.REPORT_LIST]
  }
]

const USER_SERVICES = [
  {
    URL: '/list',
    SERVICE: SERVICES.USER,
    TYPE: TYPES.USER_LIST,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/details/{1}',
    SERVICE: SERVICES.USER,
    TYPE: TYPES.USER_DETAILS,
    METHOD: METHOD.GET,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/change-status',
    SERVICE: SERVICES.USER,
    TYPE: TYPES.CHANGE_USER_STATUS,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/updateRole',
    SERVICE: SERVICES.USER,
    TYPE: TYPES.USER_DETAILS,
    METHOD: METHOD.PUT,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/followers',
    SERVICE: SERVICES.USER,
    TYPE: TYPES.USER_FOLLOWERS,
    METHOD: METHOD.GET,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/following',
    SERVICE: SERVICES.USER,
    TYPE: TYPES.USER_FOLLOWINGS,
    METHOD: METHOD.GET,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/blocked-users',
    SERVICE: SERVICES.USER,
    TYPE: TYPES.BLOCKED_USER_LIST,
    METHOD: METHOD.GET,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/users-blocked-list',
    SERVICE: SERVICES.USER,
    TYPE: TYPES.USER_BLOCKED_LIST,
    METHOD: METHOD.GET,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/block',
    SERVICE: SERVICES.USER,
    TYPE: TYPES.POST_BLOCK_USER,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/unblock',
    SERVICE: SERVICES.USER,
    TYPE: TYPES.POST_UNBLOCK_USER,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true
  }
]

const FILE_SERVICES = [
  {
    URL: '/upload',
    SERVICE: SERVICES.CRM_FILE,
    TYPE: TYPES.CRM_FILE_UPLOAD,
    METHOD: METHOD.POST,
    CONTENT_TYPE: CONTENT_TYPE.FORM_DATA,
    SUCCESS: false,
    ERROR: true
  }
]

const SOCIAL_POST_SERVICES = [
  {
    URL: '/get-list',
    SERVICE: SERVICES.CRM_SOCIAL_POST,
    TYPE: TYPES.SOCIAL_POST_LIST,
    METHOD: METHOD.GET,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/get-list-by-userid',
    SERVICE: SERVICES.CRM_SOCIAL_POST,
    TYPE: TYPES.SOCIAL_USER_POSTS,
    METHOD: METHOD.GET,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/get-list-by-tag',
    SERVICE: SERVICES.CRM_SOCIAL_POST,
    TYPE: TYPES.SOCIAL_POSTS_BY_TAG,
    METHOD: METHOD.GET,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/get-by-id',
    SERVICE: SERVICES.CRM_SOCIAL_POST,
    TYPE: TYPES.SOCIAL_POST_BY_ID,
    METHOD: METHOD.GET,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/delete-post',
    SERVICE: SERVICES.CRM_SOCIAL_POST,
    TYPE: TYPES.SOCIAL_POST_DELETE,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/get-hashtags',
    SERVICE: SERVICES.CRM_SOCIAL_POST,
    TYPE: TYPES.SOCIAL_GET_HASHTAGS,
    METHOD: METHOD.GET,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/add-hashtag',
    SERVICE: SERVICES.CRM_SOCIAL_POST,
    TYPE: TYPES.SOCIAL_ADD_HASHTAG,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true,
    RE_FETCH: [TYPES.SOCIAL_GET_HASHTAGS]
  },
  {
    URL: '/delete-hashtag',
    SERVICE: SERVICES.CRM_SOCIAL_POST,
    TYPE: TYPES.SOCIAL_DELETE_HASHTAG,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true,
    RE_FETCH: [TYPES.SOCIAL_GET_HASHTAGS]
  },
  {
    URL: '/update-hashtag',
    SERVICE: SERVICES.CRM_SOCIAL_POST,
    TYPE: TYPES.SOCIAL_UPDATE_HASHTAG,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true,
    RE_FETCH: [TYPES.SOCIAL_GET_HASHTAGS]
  },
  {
    URL: '/comments',
    SERVICE: SERVICES.CRM_SOCIAL_POST,
    TYPE: TYPES.SOCIAL_POST_COMMENTS,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true
  },

  {
    URL: '/update',
    SERVICE: SERVICES.CRM_SOCIAL_POST,
    TYPE: TYPES.SOCIAL_POST_UPDATE,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true,
    RE_FETCH: [TYPES.SOCIAL_POST_LIST]
  },
  {
    URL: '/restrict-comment',
    SERVICE: SERVICES.CRM_SOCIAL_POST,
    TYPE: TYPES.SOCIAL_POST_RESTRICT_COMMENT,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/user-stories',
    SERVICE: SERVICES.CRM_SOCIAL_POST,
    TYPE: TYPES.SOCIAL_USER_STORIES,
    METHOD: METHOD.GET,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/get-stories',
    SERVICE: SERVICES.OFFICIAL_ACCOUNT,
    TYPE: TYPES.SOCIAL_OFFICIAL_STORIES,
    METHOD: METHOD.GET,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/get-posts',
    SERVICE: SERVICES.OFFICIAL_ACCOUNT,
    TYPE: TYPES.SOCIAL_OFFICIAL_POSTS,
    METHOD: METHOD.GET,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/delete-post',
    SERVICE: SERVICES.OFFICIAL_ACCOUNT,
    TYPE: TYPES.SOCIAL_OFFICIAL_POST_DELETE,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true,
    RE_FETCH: [TYPES.SOCIAL_OFFICIAL_POSTS]
  },
  {
    URL: '/create-post',
    SERVICE: SERVICES.OFFICIAL_ACCOUNT,
    TYPE: TYPES.SOCIAL_OFFICIAL_POST_CREATE,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true,
    RE_FETCH: [TYPES.SOCIAL_OFFICIAL_POSTS]
  },
  {
    URL: '/update-post',
    SERVICE: SERVICES.OFFICIAL_ACCOUNT,
    TYPE: TYPES.SOCIAL_OFFICIAL_POST_UPDATE,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true,
    RE_FETCH: [TYPES.SOCIAL_OFFICIAL_POSTS]
  }
]

const SOCIAL_COMMENT_SERVICES = [
  {
    URL: '/delete-releated-comments',
    SERVICE: SERVICES.CRM_SOCIAL_COMMENT,
    TYPE: TYPES.SOCIAL_DELETE_RELATED_COMMENTS,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/comments-by-post-id',
    SERVICE: SERVICES.CRM_SOCIAL_COMMENT,
    TYPE: TYPES.SOCIAL_COMMENTS_BY_POST_ID,
    METHOD: METHOD.GET,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/delete-comment-by-id',
    SERVICE: SERVICES.CRM_SOCIAL_COMMENT,
    TYPE: TYPES.SOCIAL_DELETE_COMMENT_BY_ID,
    METHOD: METHOD.POST,
    SUCCESS: false,
    ERROR: true
  },
  {
    URL: '/get-comment-by-id',
    SERVICE: SERVICES.CRM_SOCIAL_COMMENT,
    TYPE: TYPES.SOCIAL_GET_COMMENT_BY_ID,
    METHOD: METHOD.GET,
    SUCCESS: false,
    ERROR: true
  }
]

export const CLIENT_CONFIG: TconfigObj[] = [
  ...AUTH_SERVICES,
  ...CHAT_SERVICES,
  ...CRM_USER_SERVICES,
  ...HISTORY_SERVICES,
  ...REPORT_SERVICES,
  ...USER_SERVICES,
  ...SOCIAL_POST_SERVICES,
  ...FILE_SERVICES,
  ...SOCIAL_COMMENT_SERVICES
]
