import { ReactNode } from 'react'

import { Form } from 'antd'
import { FormItemProps as FormItemPropsAntd, Rule } from 'antd/lib/form'

import { translate } from 'src/common/utils/translateUtil'

import TranslatedText from 'src/components/translated-text/TranslatedText'

import 'src/assets/styles/_form.scss'

export interface FormItemProps extends FormItemPropsAntd {
  name: string | number | Array<string | number> | undefined
  label?: string | null
  rules?: Rule[] | Array<Record<string, unknown>> | any
  notform?: boolean
  marginReset?: boolean
  translateParams?: string[]
  children?: ReactNode
}

function FormItem(props: FormItemProps) {
  // Destruct Props
  const { children, label, notform, className, marginReset, rules, translateParams, help } = props

  const customRule: Array<Record<string, unknown>> = []

  rules &&
    rules?.map((item) => {
      customRule?.push({
        ...item,
        message: translateParams != null ? translate(item?.message, translateParams) : translate(item?.message)
      })

      return true
    })

  return notform ? (
    <div>{children}</div>
  ) : (
    <div className="custom-form-item">
      <Form.Item
        {...props}
        label={label && <TranslatedText label={label} />}
        className={`${className} ${marginReset && 'no_margin'}`}
        rules={customRule}
        help={typeof help === 'string' ? translate(help) : help}
      >
        {children}
      </Form.Item>
    </div>
  )
}

export default FormItem
