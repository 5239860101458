import { LazyExoticComponent, lazy } from 'react'

import { all_users } from 'src/common/constants//roles-rights/roles'

// import { HOMEPAGE } from "src/common/constants/roles-rights/rights";

// import { DEFAULT } from "src/common/constants/roles-rights/rights";

const Dashboard = lazy(async () => import('src/pages/dashboard/Dashboard'))

// SETTINGS
const RoleRight = lazy(async () => import('src/pages/settings/role-right/RoleRight'))
const RoleRightManagement = lazy(async () => import('src/pages/settings/role-right/_partials/RoleRightManagement'))
const Users = lazy(async () => import('src/pages/settings/users/Users'))
const BlockedUsers = lazy(async () => import('src/pages/settings/blocked-users/BlockedUsers'))

// SOCIAL FEED MANAGEMENT
const UsersManagement = lazy(async () => import('src/pages/users-management/UsersManagement'))
const UsersManagementDetail = lazy(async () => import('src/pages/users-management/UsersManagementDetail'))

// COMPLAINT MANAGEMENT
const ComplaintManagement = lazy(async () => import('src/pages/complaint-management/ComplaintManagement'))

// ANNOUNCEMENT MANAGEMENT
const AnnouncementManagement = lazy(async () => import('src/pages/announcement-management/AnnouncementManagement'))

// APP MANAGEMENT
const CarouselManagement = lazy(async () => import('src/pages/app-management/carousel-management/CarouselManagement'))

// CONTACT US
const ContactUs = lazy(async () => import('src/pages/contact-us/ContactUs'))

// MESSAGING MANAGEMENT
const MessagingManagement = lazy(async () => import('src/pages/messaging-management/MessagingManagement'))

// SOCIAL FEED MANAGEMENT
const SocialFeed = lazy(async () => import('src/pages/social-feed-management/social-feed/SocialFeed'))
const CategoryManagement = lazy(
  async () => import('src/pages/social-feed-management/category-management/CategoryManagement')
)
const PawderAdmin = lazy(async () => import('src/pages/social-feed-management/pawder-official/PawderOfficial'))

// EDUCATION FEED MANAGEMENT
const EducationFeedManagement = lazy(async () => import('src/pages/education-feed-management/EducationFeedManagement'))

interface TRoute {
  title: string
  exact: boolean
  path: string
  hasProfile: string[]
  hasRight?: string[]
  redirectUrl: string
  component: LazyExoticComponent<() => JSX.Element>
  pageTitle: boolean
}

// STAFF ROUTES
const complaint_management: TRoute[] = [
  {
    title: 'PAGE_TITLES.COMPLAINT_MANAGEMENT',
    exact: true,
    path: '/complaint-management',
    hasProfile: all_users,
    // hasRight: HOMEPAGE,
    redirectUrl: '/',
    component: ComplaintManagement,
    pageTitle: true
  },
  {
    title: 'PAGE_TITLES.ANNOUNCEMENT_MANAGEMENT',
    exact: true,
    path: '/announcement-management',
    hasProfile: all_users,
    // hasRight: HOMEPAGE,
    redirectUrl: '/',
    component: AnnouncementManagement,
    pageTitle: true
  }
]

// APP MANAGEMENT
const app_management: TRoute[] = [
  {
    title: 'PAGE_TITLES.CAROUSEL_MANAGEMENT',
    exact: true,
    path: '/carousel-management',
    hasProfile: all_users,
    // hasRight: HOMEPAGE,
    redirectUrl: '/',
    component: CarouselManagement,
    pageTitle: true
  }
]

// APP MANAGEMENT
const contact_us: TRoute[] = [
  {
    title: 'PAGE_TITLES.CONTACT_US',
    exact: true,
    path: '/contact-us',
    hasProfile: all_users,
    // hasRight: HOMEPAGE,
    redirectUrl: '/',
    component: ContactUs,
    pageTitle: true
  }
]

// MESSAGING MANAGEMENT
const messaging_management: TRoute[] = [
  {
    title: 'PAGE_TITLES.MESSAGING_MANAGEMENT',
    exact: true,
    path: '/messaging-management',
    hasProfile: all_users,
    // hasRight: HOMEPAGE,
    redirectUrl: '/',
    component: MessagingManagement,
    pageTitle: true
  }
]

// SOCIAL FEED
const social_feed: TRoute[] = [
  {
    title: 'PAGE_TITLES.SOCIAL_FEED',
    exact: true,
    path: '/social-feed',
    hasProfile: all_users,
    // hasRight: HOMEPAGE,
    redirectUrl: '/',
    component: SocialFeed,
    pageTitle: true
  }
]

// PAWDER OFFICIAL
const pawder_official: TRoute[] = [
  {
    title: 'PAGE_TITLES.PAWDER_OFFICIAL',
    exact: true,
    path: '/pawder-official',
    hasProfile: all_users,
    // hasRight: HOMEPAGE,
    redirectUrl: '/',
    component: PawderAdmin,
    pageTitle: true
  }
]

// CATEGORY MANAGEMENT
const category_management: TRoute[] = [
  {
    title: 'PAGE_TITLES.CATEGORY_MANAGEMENT',
    exact: true,
    path: '/category-management',
    hasProfile: all_users,
    // hasRight: HOMEPAGE,
    redirectUrl: '/',
    component: CategoryManagement,
    pageTitle: true
  }
]

// EDUCATION FEED MANAGEMENT
const education_feed_management: TRoute[] = [
  {
    title: 'PAGE_TITLES.EDUCATION_FEED_MANAGEMENT',
    exact: true,
    path: '/education-feed-management',
    hasProfile: all_users,
    // hasRight: HOMEPAGE,
    redirectUrl: '/',
    component: EducationFeedManagement,
    pageTitle: true
  }
]

// SOCIAL FEED MANAGEMENT
const users_management: TRoute[] = [
  {
    title: 'PAGE_TITLES.USERS_MANAGEMENT',
    exact: true,
    path: '/users-management',
    hasProfile: all_users,
    // hasRight: HOMEPAGE,
    redirectUrl: '/',
    component: UsersManagement,
    pageTitle: true
  },
  {
    title: 'PAGE_TITLES.USERS_MANAGEMENT',
    exact: true,
    path: '/users-management/detail/:_id',
    hasProfile: all_users,
    // hasRight: HOMEPAGE,
    redirectUrl: '/',
    component: UsersManagementDetail,
    pageTitle: false
  }
]

// SETTINGS ROUTES
const settings: TRoute[] = [
  {
    title: 'PAGE_TITLES.ROLE_RIGHT',
    exact: true,
    path: '/settings/role-right',
    hasProfile: all_users,
    // hasRight: HOMEPAGE,
    redirectUrl: '/',
    component: RoleRight,
    pageTitle: true
  },
  {
    title: '',
    exact: true,
    path: '/settings/role-right/management',
    hasProfile: all_users,
    // hasRight: HOMEPAGE,
    redirectUrl: '/',
    component: RoleRightManagement,
    pageTitle: false
  },
  {
    title: 'PAGE_TITLES.USERS',
    exact: true,
    path: '/settings/users',
    hasProfile: all_users,
    // hasRight: HOMEPAGE,
    redirectUrl: '/',
    component: Users,
    pageTitle: true
  },
  {
    title: 'PAGE_TITLES.BLOCKED_USERS',
    exact: true,
    path: '/settings/blocked-users',
    hasProfile: all_users,
    // hasRight: HOMEPAGE,
    redirectUrl: '/',
    component: BlockedUsers,
    pageTitle: true
  }
]

// EXPORT ROUTES
export const routes: TRoute[] = [
  {
    title: 'PAGE_TITLES.DASHBOARD',
    exact: true,
    path: '/',
    hasProfile: all_users,
    // hasRight: HOMEPAGE,
    redirectUrl: '/',
    component: Dashboard,
    pageTitle: true
  },
  ...app_management,
  ...users_management,
  ...complaint_management,
  ...messaging_management,
  ...education_feed_management,
  ...social_feed,
  ...category_management,
  ...pawder_official,
  ...contact_us,
  ...settings
]
