// import {  } from '@fortawesome/free-brands-svg-icons';
import {
  faAddressBook,
  faBell,
  faCheckCircle,
  faComment,
  faCompass,
  faMinusSquare,
  faMoon,
  faThumbsUp
} from '@fortawesome/free-regular-svg-icons'
import {
  faAddressCard,
  faAngleDown,
  faAngleRight,
  faAngleUp,
  faArrowLeft,
  faArrowRight,
  faBan,
  faBars,
  faBook,
  faBox,
  faBriefcase,
  faBuilding,
  faBullhorn,
  faCalculator,
  faCalendarWeek,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faChartArea,
  faChartBar,
  faCheck,
  faCheckSquare,
  faCircleChevronLeft,
  faCircleChevronRight,
  faCog,
  faCogs,
  faCoins,
  faCommentDots,
  faCompress,
  faCopy,
  faDatabase,
  faDoorOpen,
  faEdit,
  faEllipsisVertical,
  faEnvelope,
  faExchangeAlt,
  faExclamationCircle,
  faExpand,
  faEye,
  faFileAlt,
  faFileArrowDown,
  faFileCircleExclamation,
  faFileCsv,
  faFileDownload,
  faFileExcel,
  faFileExport,
  faFilePdf,
  faFilter,
  faFilterCircleXmark,
  faFlag,
  faFloppyDisk,
  faHome,
  faInfoCircle,
  faLifeRing,
  faLightbulb,
  faLock,
  faLongArrowAltRight,
  faMobileAlt,
  faPaperPlane,
  faPaperclip,
  faPlane,
  faPlay,
  faPlus,
  faRepeat,
  faRotate,
  faSchoolFlag,
  faScroll,
  faSearch,
  faShareNodes,
  faSignOutAlt,
  faSitemap,
  faSlidersH,
  faTh,
  faTimeline,
  faTrash,
  faUpload,
  faUser,
  faUserCircle,
  faUsersCog,
  faWallet,
  faWindowClose,
  faXmark
} from '@fortawesome/free-solid-svg-icons'

export const ICON_LIST = {
  COMPRESS: faCompress,
  EXPAND: faExpand,
  BARS: faBars,
  LONG_ARROW_RIGHT: faLongArrowAltRight,
  ARROW_RIGHT: faArrowRight,
  ARROW_LEFT: faArrowLeft,
  BELL: faBell,
  CIRCLE_CHEVRON_LEFT: faCircleChevronLeft,
  CIRCLE_CHEVRON_RIGHT: faCircleChevronRight,
  COG: faCog,
  USER: faUser,
  USER_CIRCLE: faUserCircle,
  ADDRESS_CARD: faAddressCard,
  CHART_BAR: faChartBar,
  CHART_AREA: faChartArea,
  HOME: faHome,
  BRIEFCASE: faBriefcase,
  FILE_ALT: faFileAlt,
  COINS: faCoins,
  BULLHORN: faBullhorn,
  SCROLL: faScroll,
  USERS_COG: faUsersCog,
  EDIT: faEdit,
  CLOSE: faWindowClose,
  CHECK: faCheckSquare,
  CHECK_DEFAULT: faCheck,
  EXCEL: faFileExcel,
  PDF: faFilePdf,
  CSV: faFileCsv,
  EXPORT: faFileExport,
  DOWNLOAD: faFileDownload,
  CARET_DOWN: faCaretDown,
  CARET_UP: faCaretUp,
  CARET_LEFT: faCaretLeft,
  CARET_RIGHT: faCaretRight,
  SEARCH: faSearch,
  EYE: faEye,
  COPY: faCopy,
  COMMENTS: faCommentDots,
  BUILDING: faBuilding,
  PLUS: faPlus,
  MINUS_SQUARE: faMinusSquare,
  UPLOAD: faUpload,
  EXCLAMATION: faExclamationCircle,
  SWAP: faExchangeAlt,
  FLAG: faFlag,
  INFO: faInfoCircle,
  SLIDERS: faSlidersH,
  ANGLE_RIGHT: faAngleRight,
  ANGLE_UP: faAngleUp,
  ANGLE_DOWN: faAngleDown,
  LOGOUT: faSignOutAlt,
  CALCULATOR: faCalculator,
  TH: faTh,
  FILTER: faFilter,
  FILTER_REMOVE: faFilterCircleXmark,
  DELETE: faTrash,
  FILE_ARROW_DOWN: faFileArrowDown,
  LOCK: faLock,
  WALLET: faWallet,
  LIFE_RING: faLifeRing,
  PAPER_PLANE: faPaperPlane,
  DOOR_OPENED: faDoorOpen,
  ELLIPSIS_VERTICAL: faEllipsisVertical,
  LIGHT_BULB: faLightbulb,
  MOON: faMoon,
  PAPER_CLIP: faPaperclip,
  PLANE: faPlane,
  SETTINGS: faCogs,
  REFRESH: faRotate,
  DATABASE: faDatabase,
  SITEMAP: faSitemap,
  SCHOOL: faSchoolFlag,
  SAVE: faFloppyDisk,
  XMARK: faXmark,
  REPEAT: faRepeat,
  CALENDER_WEEK: faCalendarWeek,
  PLAY: faPlay,
  FILE_CIRCLE_EXCLAMATION: faFileCircleExclamation,
  BOX: faBox,
  CALENDAR: faCalendarWeek,
  TIME: faTimeline,
  COMPASS: faCompass,
  BOOK: faBook,
  ENVELOPE: faEnvelope,
  MOBILE_PHONE: faMobileAlt,
  ADDRESS_BOOK: faAddressBook,
  BAN: faBan,
  CHECK_CIRCLE: faCheckCircle,
  SHARE_NODES: faShareNodes,
  LIKE: faThumbsUp,
  COMMENT: faComment
}
