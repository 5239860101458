import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button as ButtonAntd } from 'antd'
import { ButtonProps as ButtonPropsAntd } from 'antd/lib/button'

import { BUTTON_SIZES } from 'src/common/constants/sizes/buttonSizes'
import { MARGIN_SIZES } from 'src/common/constants/sizes/marginSizes'
import { STATUS } from 'src/common/constants/status/status'
import { TEXT_WEIGHT } from 'src/common/constants/text/textWeight'

import FormItem from 'src/components/form-elements/form-item/FormItem'
import TranslatedText from 'src/components/translated-text/TranslatedText'

import 'src/assets/styles/_buttons.scss'

export interface ButtonProps extends Omit<ButtonPropsAntd, 'size'> {
  status?: STATUS | undefined
  label?: string | undefined
  customLabel?: string | React.ReactElement | ReactNode | undefined
  icon?: ReactNode
  rightIcon?: ReactNode
  className?: string | undefined
  sizes?: BUTTON_SIZES | undefined
  block?: boolean
  marginright?: MARGIN_SIZES
  marginleft?: MARGIN_SIZES
  margintop?: MARGIN_SIZES
  marginbottom?: MARGIN_SIZES
  isform?: boolean
  name: string
  push?: string
  pushState?: any
  disabled?: boolean
  labelParams?: string[]
}

function Button(props: ButtonProps) {
  // Desctruct Props
  const {
    label,
    customLabel,
    status,
    icon,
    rightIcon,
    className = '',
    sizes,
    block,
    marginright,
    marginleft,
    margintop,
    marginbottom,
    isform,
    name,
    push,
    pushState,
    onClick,
    disabled,
    labelParams
  } = props

  // Variables
  const navigate = useNavigate()

  const buttonTemplate = (
    <div className={block ? 'full-wrap' : 'inline-wrap'}>
      <ButtonAntd
        {...props}
        disabled={disabled}
        id={name}
        onClick={push ? () => (pushState ? navigate(push, { state: { ...pushState } }) : navigate(push)) : onClick}
        className={
          status
            ? `btn-${status} ${className} ${sizes} 
          margin_right_${marginright} margin_left_${marginleft} margin_top_${margintop} margin_bottom_${marginbottom}`
            : `${className} 
          margin_right_${marginright} margin_left_${marginleft} margin_top_${margintop} margin_bottom_${marginbottom}`
        }
        icon={icon && <span className={`btn-icon ${(label || customLabel) && 'margin_right_5'}`}>{icon}</span>}
      >
        {label ? <TranslatedText label={label} labelParams={labelParams} weight={TEXT_WEIGHT.SEMIBOLD} /> : ''}
        {customLabel && <span>{customLabel}</span>}
        {rightIcon && <span className={`btn-icon ${label && 'margin_left_10'}`}>{rightIcon}</span>}
      </ButtonAntd>
    </div>
  )

  return isform ? <FormItem name={name}>{buttonTemplate}</FormItem> : buttonTemplate
}
export default Button

Button.defaultProps = {
  block: false,
  sizes: BUTTON_SIZES.MD
}
