import { createMutation, createQuery } from 'react-query-kit'

import axios from 'axios'
import qs from 'qs'
import { localLogout } from 'src/store/actions/auth/localLogout'
import { store } from 'src/store/store'

import { ERROR_CODES } from 'src/common/constants/error-codes/errorCodes'

import { notificationUtil } from 'src/common/utils/notificationUtils'

import { queryClient } from './api-config/queryClient'
import { CLIENT_CONFIG } from './api-config/servicesConfig'
import { TYPES } from './api-config/types'

import i18n from '../common/locales/i18n'

// Eğer path içine parametre gönderiliyorsa eşleme için kullanılır
const urlParser = (url: string, values: string[]) => {
  let newUrl = url

  values &&
    values?.forEach((param, paramIndex) => {
      newUrl = url?.replace(`{${paramIndex + 1}}`, param)
    })

  return newUrl
}

export const useFetcher = (RequestType: TYPES, useSocialBaseUrl?: boolean) => {
  // Verilen RequestType'dan Request Configini Bul
  const myRequestConfig = CLIENT_CONFIG?.find((config) => config?.TYPE === RequestType)
  const method = myRequestConfig?.METHOD
  const url = `${myRequestConfig?.SERVICE}${myRequestConfig?.URL}`

  // Request Type Client Config İçinde Yoksa Error Bas
  if (myRequestConfig == null) {
    new Error("Request Type yanlış girildi veya Client Config'de tanımlanmadı!")
  }

  // SUCCESS HANDLER FUNCTION
  const successHandler = async (data: any) => {
    // Store
    if (myRequestConfig?.REDUCER_TYPE) {
      if (myRequestConfig?.URL === '/login') {
        store.dispatch({
          type: `${myRequestConfig?.REDUCER_TYPE}_SUCCESS`,
          payload: data?.data
        })
      } else {
        store.dispatch({
          type: `${myRequestConfig?.REDUCER_TYPE}_SUCCESS`,
          payload: data
        })
      }
    }

    // Re-fetch handler
    myRequestConfig?.RE_FETCH?.length &&
      myRequestConfig.RE_FETCH?.forEach(async (item) =>
        queryClient.invalidateQueries({ queryKey: [item], refetchType: 'all' })
      )

    // Notification
    if (typeof myRequestConfig?.SUCCESS === 'string') {
      notificationUtil('GLOBAL.COMPONENTS.NOTIFICATIONS.GENERAL.SUCCESS', myRequestConfig?.SUCCESS, 'success')
    } else {
      myRequestConfig?.SUCCESS &&
        notificationUtil('GLOBAL.COMPONENTS.NOTIFICATIONS.GENERAL.SUCCESS', undefined, 'success')
    }
  }

  // ERROR HANDLER FUNCTION
  const errorHandler = (error: any) => {
    // Eğer error 401 ise logout yap
    if (error?.response?.status === 401) {
      store.dispatch(localLogout())
      sessionStorage.clear()

      // Eğer authentication false ise logout url'e yönlendir
      if (process.env.REACT_APP_AUTHENTICATION !== 'true') {
        sessionStorage.setItem('lastCrmPawderUrl', '/')
        window.location.href = String(process.env.REACT_APP_IDP_LOGOUT_URL)
      }

      return
    }

    // Store
    if (myRequestConfig?.REDUCER_TYPE) {
      store.dispatch({
        type: `${myRequestConfig?.REDUCER_TYPE}_FAILED`,
        payload: error
      })
    }

    // Notification

    // Request Config içinde ERROR'un string olması durumu
    if (typeof myRequestConfig?.ERROR === 'string') {
      notificationUtil('GLOBAL.COMPONENTS.NOTIFICATIONS.GENERAL.ERROR.MESSAGE', myRequestConfig?.ERROR, 'error')
    }
    // Error Codes içinde ERROR varsa onu bas
    else if (myRequestConfig?.ERROR) {
      const errorData = error?.response?.data
      const myErrorConfig = ERROR_CODES?.find((item) => item?.code === errorData?.errorCode)
      myErrorConfig != null
        ? notificationUtil(myErrorConfig?.title, myErrorConfig?.description, 'error')
        : notificationUtil('GLOBAL.COMPONENTS.NOTIFICATIONS.GENERAL.ERROR.MESSAGE', undefined, 'error')
    }
  }

  // Axios Instance
  const axiosInstance = axios.create()

  // Created Request
  const createdRequest = (variables?: any) => {
    // Reducer'ın type'ı varsa loading dispatch et
    if (myRequestConfig?.REDUCER_TYPE) {
      store.dispatch({
        type: `${myRequestConfig?.REDUCER_TYPE}_LOADING`,
        payload: null
      })
    }
    return axiosInstance({
      // Axios config
      baseURL: useSocialBaseUrl ? `${process.env.REACT_APP_API_URL2}` : `${process.env.REACT_APP_API_URL}`,
      url: Array.isArray(variables) ? urlParser(url, variables?.[1]) : url,
      method,
      headers: {
        Authorization: `Bearer ${sessionStorage?.getItem('jwtToken')}`,
        UserLanguage: `${i18n?.language}`,
        'Content-type': myRequestConfig?.CONTENT_TYPE || 'application/json'
      },
      data: method === 'GET' ? undefined : Array.isArray(variables) ? variables?.[0] : variables,
      params:
        method === 'GET' || method === 'DELETE' ? (Array.isArray(variables) ? variables?.[0] : variables) : undefined,
      paramsSerializer: {
        serialize: (params) => qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  }

  // useQuery
  const query = createQuery({
    // Query için unique bir key oluşturur. Bu key ile query'ye erişilir.
    queryKey: [`${myRequestConfig?.TYPE}`],

    // Query'nin çalışması için gerekli olan fonksiyon.
    fetcher: (variables) => createdRequest(variables),

    // Başarılı olursa çalışacak fonksiyon.
    // onSuccess(data) {
    //   successHandler(data)
    // },

    // // Hata alınırsa çalışacak fonksiyon.
    // onError(error) {
    //   errorHandler(error)
    // },

    // Query'nin konfigürasyonu.
    ...myRequestConfig?.CUSTOM_REQUEST_CONFIG
  })

  // useMutation
  const mutation = createMutation({
    // Mutation için unique bir key oluşturur. Bu key ile mutation'a erişilir.
    mutationFn: async (variables?: any) => createdRequest(variables),

    // Başarılı olursa çalışacak fonksiyon.
    onSuccess: async function onSuccess(data) {
      await successHandler(data)
    },

    // Hata alınırsa çalışacak fonksiyon.
    onError(error) {
      errorHandler(error)
    },

    // Mutation'nin konfigürasyonu.
    ...myRequestConfig?.CUSTOM_REQUEST_CONFIG
  })

  // Query Function
  const queryFunc = (variables?: any) => query({ variables })

  // Return Data
  const apiResponse = { render: queryFunc, action: mutation }

  return apiResponse
}
